
  import { Component, Prop } from 'vue-property-decorator'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import FileView from '@/components/core/files/FileView.vue'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component({
  components: { FileView, FieldTitle },
})
  export default class Attachments extends FilesProcess {
  @Prop({ type: Array, default: () => [] }) value!: any[]

  openFile (fileProcess) {
    fileProcess.map(file => {
      this.openLink(file.file.uri)
    })
  }
  }
